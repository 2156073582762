import * as React from "react";
import {useContext, useEffect} from "react";
import Hotel from "../models/Hotel";
import {match, useHistory} from "react-router";
import { Location } from 'history';
import {connect, useDispatch, useSelector} from "react-redux";
import "../assets/styles/home-page.css"
import "../assets/styles/experience.css"
import "../assets/css/helpers.css"
import {Helmet} from "react-helmet-async";
import {ActivityDate, ExperienceModel, Ticket} from "../models/ExperienceModel";
import Loader from "../components/common/Loader";
import {useState} from "react";
import {fetchExperienceService} from "../services/ExperienceService";
import {fetchExperienceAction} from "../store/actions/ExperienceActions/ExperienceActionCreators";
import {getLocale} from "../store/app/selectors";
import ExperienceContext, {ActivityBooking, BookingForm, ResourceBooking} from "../ExperienceContext";
import {getHcQuery, getQueryParam, hasQueryParam} from "../helpers/ExperienceHelper";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {ExperienceVisited, HotelVisited2} from "../services/GigglyticsService";
import {stripHtml} from "../../server/helpers/helmetHelper";
import {getImageSharingUrl, toSeoUrl} from "../helpers/GeneralHelper";

//v2 imports
import "../assets/styles/v2/experience.css"
import closeIcon  from '../assets/icons/close.svg';
import closeIconBlack  from '../assets/icons/close-black.svg';
import ImageSlider from "../components/experience-page/ImageSlider";
import useWindowDimensions from "../helpers/useWindowDimensions";
import '../assets/css/v2/experience-detail.css';
import MasonryGrid from "components/common/MasonryGrid";
import {useLocation} from "react-router-dom";
import {fetchCategoryExperiences} from "services/PortalService";
import ActivityExpereinceV2 from "components/experience-page/activity/ActivityExpereinceV2";
import ResourceExperienceV2 from "components/experience-page/resource/ResourceExperienceV2";
import 'moment/locale/de';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/sl';
import 'moment/locale/sk';
import 'moment/locale/nb';
import 'moment/locale/ja';
import 'moment/locale/nl';
import 'moment/locale/vi';
import 'moment/locale/pt';
import 'moment/locale/el';
import DeactivatedAccount from "pages/DeactivatedAccount";
import {fetchHotelAction} from "store/actions/HotelActions/HotelsActionCreators";
import TranslationUtils from "helpers/TranslationUtils";


interface IExperienceProps {
    hotel: Hotel,
    experience: ExperienceModel
    match: match,
    location: Location
};
let firstTime = false;
let widthUpdated = 0;
function Experience(props: IExperienceProps) {
    const {expState} = useContext(ExperienceContext);
    const location = useLocation();
    const {t} = useTranslation();
    const locale = useSelector(getLocale);
    const history = useHistory();
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading: false,
        error: false,
        notFound: false
    });
    const { width } = __BROWSER__ ? useWindowDimensions() : { width: 0 };
    const [mobileLeftSlideNumber] = useState(0)
    const [mobileRightSlideNumber] = useState(0)
    const [experienceState, setExperienceState] = useState({
        experience: props.experience,
        hotel: props.hotel,
        activityBooking: {} as ActivityBooking,
        resourceBooking: {} as ResourceBooking,
        tickets: [] as Ticket[],
        bookingForm: {} as BookingForm,
        fbclid: getQueryParam(props.location.search, 'fbclid'),
        redirectTo: getQueryParam(props.location.search, 'redirectTo'),
        redirectHotelCode: hasQueryParam(props.location.pathname, 'hc') || getHcQuery(props.location.search),
        buttonColor: getQueryParam(props.location.search, 'buttonColor'),
        fontColor: getQueryParam(props.location.search, 'fontColor'),
        whiteBg: getQueryParam(props.location.search, 'whiteBg'),
        noWindowsEnabled: null,
        activityStep: 1,
        resourceStep: 1
    });
    const isHotelTemplate = props.location.pathname.indexOf('/experiences') >= 0;
    const [similarExperiences, setSimilarExperiences] = useState([]);
    const divId = getQueryParam(props.location.search, 'divId');
    const hideBackButton = getQueryParam(props.location.search, 'hideBackButton');

    useEffect(() => {
        moment.locale(locale);
        widthUpdated = width;
        const loadExperience = async () => {
            setState({
                ...state,
                loading: true,
                error: false,
                notFound: false
            });
            const experienceResult: any = await fetchExperienceService((props.match.params as any).experienceId, locale);
            if (experienceResult === 404) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: true,
                });
            }
            else if (experienceResult === 500) {
                setState({
                    ...state,
                    loading: false,
                    error: true,
                    notFound: false,
                });
            }
            else {
                dispatch(fetchHotelAction(experienceResult.hotel))
                dispatch(fetchExperienceAction(experienceResult));
                setExperienceState({
                    ...experienceState,
                    experience: experienceResult,
                    tickets: [] as Ticket[],
                    activityBooking: {} as ActivityBooking,
                    resourceBooking: {} as ResourceBooking,
                    bookingForm: {} as BookingForm,
                    hotel: experienceResult.hotel,
                    noWindowsEnabled: null
                });
                setState({
                    ...state,
                    loading: false
                });
            }
        };

        if (
            __BROWSER__ &&
            (
                Object.keys(props.experience).length == 0 ||
                (props.match.params as any).experienceId !== (props.experience?.id || 'DEF_ID')
            )
        ) {
            loadExperience();
        }

        // loadExperience();
    }, [(props.match.params as any).experienceId]);

    const isFromHostSources = (): boolean => {
        if (
            !location.state &&
            !Boolean(getHcQuery(location.search)) &&
            !Boolean(getQueryParam(location.search, 'redirectTo')) &&
            !Boolean(getQueryParam(location.search, 'fbclid'))
        ) return false;

        return Boolean(experienceState.redirectHotelCode || experienceState.redirectTo || Boolean(getHcQuery(location.search)) || Boolean(getQueryParam(location.search, 'fbclid')));
    }

    useEffect(() => {
        const populateSimilars = async () => {
            const categoryExperiencesResults = await fetchCategoryExperiences(props.experience.category.translation.urlName || '', locale, 1, 50);
            setSimilarExperiences((categoryExperiencesResults as any).results || [])
        }
        if (similarExperiences.length === 0 && Object.keys(props.experience).length && !isFromHostSources()) {
            populateSimilars();
        }
    }, [props.experience]);

    const goBack = () => {
        setExperienceState({
            experience: {} as ExperienceModel,
            hotel: {} as Hotel,
            activityBooking: {} as ActivityBooking,
            resourceBooking: {} as ResourceBooking,
            tickets: [] as Ticket[],
            bookingForm: {} as BookingForm,
            redirectTo: getQueryParam(props.location.search, 'redirectTo'),
            redirectHotelCode: getHcQuery(props.location.search),
            buttonColor: getQueryParam(props.location.search, 'buttonColor'),
            fontColor: getQueryParam(props.location.search, 'fontColor'),
            whiteBg: getQueryParam(props.location.search, 'whiteBg'),
            noWindowsEnabled: null
        });

        if (experienceState.redirectTo) {
            const divId = getQueryParam(props.location.search, 'divId');
            window.location.assign(decodeURIComponent(experienceState.redirectTo) + `#${divId}`)
        } else if (experienceState.fbclid) {
            history.push({
                pathname: `${locale == 'en' ? '' : '/'+locale}/${experienceState.hotel.code}`,
            });
        } else {
            dispatch(fetchExperienceAction({} as ExperienceModel));
            if (props.location.state) {
                if ((props.location.state as any).fromPortalPath) {
                    history.push({
                        pathname: (props.location.state as any).fromPortalPath
                    });
                } else {
                    history.goBack();
                }
            } else {
                let hotelCodeQueryParam = getHcQuery(props.location.search);

                if (hotelCodeQueryParam) {
                    if (hotelCodeQueryParam === 'undefined') {
                        hotelCodeQueryParam = props.hotel.code;
                    }
                    history.push({
                        pathname: `${locale == 'en' ? '' : '/'+locale}/${hotelCodeQueryParam || experienceState.hotel.code}`,
                    });
                } else {
                    history.push({
                        pathname: `/`,
                    });
                }
            }
        }
    };

    if ((Object.keys(props.experience).length == 0 || (props.match.params as any).experienceId != props.experience.id || state.loading) && !state.notFound && !state.error) {
        return (
            <div className='home-page__loader'>
                <Helmet>
                    <title>{`Loading`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <Loader />
            </div>
        );
    }

    if (state.notFound) {
        return (
            <div className='home-page__not-found'>
                <Helmet>
                    <title>{`Experience Not Found`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <h1>Experience Not Found</h1>
            </div>
        )
    }

    if (state.error) {
        return (
            <div className='home-page__not-found'>
                <Helmet>
                    <title>{`Error While Loading Hotel Data`}</title>
                    <meta name="robots" content="noindex,nofollow"/>
                </Helmet>
                <h1>Error While Loading Hotel Data</h1>
            </div>
        )
    }

    if(__BROWSER__ && !firstTime) {
        firstTime = true;
        const hc = getHcQuery(props.location.search);
        ExperienceVisited(props.experience.id, hc || props.hotel.code);
        HotelVisited2(hc || props.hotel.code);
        if (props.hotel.color && isFromHostSources()) {
            document.documentElement.style.setProperty('--link-color', `#${props.hotel.color}`);
        }

        if (props.hotel.fontColor && isFromHostSources()) {
            document.documentElement.style.setProperty('--link-font-color', `#${props.hotel.fontColor}`);
        } else {
            document.documentElement.style.setProperty('--link-font-color', `#000`);
        }

        //set body background on load
        if(experienceState.whiteBg && (widthUpdated > 575.98 || widthUpdated == 0)){
            document.body.style.backgroundColor = '#ffffff';
            document.body.style.background = '#ffffff';
        }
    }

    let noWindowsEnabled_: boolean | null = null;
    const noWindowsEnabled = () => {
        if(
            !experienceState.hotel.bookingWindowEnabled
            || (!experienceState.experience.registrationWindowTime && !experienceState.experience.registrationWindowDay)
            || experienceState.experience.registrationWindowTime == null
            || experienceState.experience.registrationWindowDay === -1
            || experienceState.experience.type != 'ACTIVITY'
        ) {
            return false;
        }


        experienceState.experience.activityDates.forEach((activityDate: ActivityDate) => {
            let registrationWindow = moment(activityDate.startDate).subtract(experienceState.experience.registrationWindowDay, 'days')
            let timeStr = String(experienceState.experience.registrationWindowTime?.slice(0,2) + ':' + String(experienceState.experience.registrationWindowTime?.slice(2,4)));

            let date = moment(registrationWindow);
            let time = moment(timeStr, 'HH:mm');
            date.set({
                hour: time.get('hour'),
                minute: time.get('minute')
            });

            let diffInMinutes = moment().diff(date, 'minutes');
            const res = diffInMinutes < 0;
            if (!res && noWindowsEnabled_ !== false) {
                noWindowsEnabled_= false;
            } else if (noWindowsEnabled_ === null) {
                noWindowsEnabled_= true;
            }
        });
        return noWindowsEnabled_;
    };

    noWindowsEnabled();
    widthUpdated = width;

    if (!props.hotel.active || Boolean(props.hotel.deletedAt) || !Boolean(props.hotel)) {
        return <DeactivatedAccount />;
    }

    const translationsLanguages = props.experience.translations.filter(x => x.title).map(x => x.languageCode);
    const autoTranslateLanguages = (props.hotel.autoTranslateLangs ?? translationsLanguages.join(',')).split(',');

    const languagesToShow = translationsLanguages.filter(language => autoTranslateLanguages.includes(language));

    return (
        <div className={'experience'} style={{
            background: experienceState.whiteBg && (widthUpdated > 575.98 || widthUpdated == 0) ? '#fff' : '#131414'
        }}>
            {
                (props.hotel.color && isFromHostSources()) &&
                <style dangerouslySetInnerHTML={{__html: `
                    :root {
                        --link-color: #${props.hotel.color};
                    }`}}>
                </style>
            }

            {
                (props.hotel.fontColor && isFromHostSources()) &&
                <style dangerouslySetInnerHTML={{__html: `
                    :root {
                        --link-font-color: #${props.hotel.fontColor};
                    }`}}>
                </style>
            }

            <Helmet>
                <title>{`${props.experience.translation.title} - ${props.hotel.name}`}</title>
                {
                    props.experience.noIndex ?
                        <meta name="robots" content="noindex,nofollow"/> :
                        (isHotelTemplate ? <meta name="robots" content="noindex,follow"/> :
                        <meta name="robots" content="all"/>)
                }

                <meta property="og:title" content={`${props.experience.translation.title}`} />
                <meta name="description" content={stripHtml(props.experience.translation.longDescription)} />
                {/*<meta property="og:description" content={stripHtml(props.experience.translation.longDescription)}/>*/}
                {/*<meta property="og:url" content={`https://giggle.tips/${locale}/experiences/${props.experience.category}/${props.experience.id}`}/>*/}
                <meta property="og:image" content={getImageSharingUrl(props.experience.imageUrl, props.experience.hasSharingImage)}/>
                <meta property="og:image:secure" content={getImageSharingUrl(props.experience.imageUrl, props.experience.hasSharingImage)}/>
                <link rel="canonical" href={`https://giggle.tips/${locale}/experience/${props.experience.id}/${toSeoUrl(props.experience.translation.title)}`} />
                {
                    languagesToShow.includes('en') && <link rel="alternate" hrefLang="x-default" href={`https://giggle.tips/en/experience/${props.experience.id}/${toSeoUrl(TranslationUtils.getTranslatedField(props.experience.translations, 'en', 'title') ?? props.experience.translation.title)}`} />
                }
                {
                    languagesToShow.includes('de') && <link rel="alternate" hrefLang="de" href={`https://giggle.tips/de/experience/${props.experience.id}/${toSeoUrl(TranslationUtils.getTranslatedField(props.experience.translations, 'de', 'title') ?? props.experience.translation.title)}`} />
                }
                {
                    languagesToShow.includes('en') && <link rel="alternate" hrefLang="en" href={`https://giggle.tips/en/experience/${props.experience.id}/${toSeoUrl(TranslationUtils.getTranslatedField(props.experience.translations, 'en', 'title') ?? props.experience.translation.title)}`} />
                }
                {
                    languagesToShow.includes('it') && <link rel="alternate" hrefLang="it" href={`https://giggle.tips/it/experience/${props.experience.id}/${toSeoUrl(TranslationUtils.getTranslatedField(props.experience.translations, 'it', 'title') ?? props.experience.translation.title)}`} />
                }
                {
                    languagesToShow.includes('fr') && <link rel="alternate" hrefLang="fr" href={`https://giggle.tips/fr/experience/${props.experience.id}/${toSeoUrl(TranslationUtils.getTranslatedField(props.experience.translations, 'fr', 'title') ?? props.experience.translation.title)}`} />
                }
                {
                    languagesToShow.includes('es') && <link rel="alternate" hrefLang="es" href={`https://giggle.tips/es/experience/${props.experience.id}/${toSeoUrl(TranslationUtils.getTranslatedField(props.experience.translations, 'es', 'title') ?? props.experience.translation.title)}`} />
                }
            </Helmet>

            <ExperienceContext.Provider value={{
                expState: {...experienceState, noWindowsEnabled: noWindowsEnabled_},
                setExpState: setExperienceState,
                closeExperience: () => goBack()
            }}>
                <div className={` experience-detail-wrapper ${experienceState.whiteBg && (widthUpdated > 575.98 || widthUpdated == 0) ? 'experience-detail-wrapper__white' : ''}`}
                    id="parent-experience-popup-id">

                    <div className="experience-slider">
                        <ImageSlider
                        images={props.experience.images}
                        guideImage={(props.experience.people || []).length ? props.experience.people[0].profilePicture : ''}
                        isWhiteBackground={(experienceState.whiteBg && (widthUpdated > 575.98 || widthUpdated == 0))}
                        />
                    </div>

                    <div className="experience-content">
                        {
                            props.experience.type === 'ACTIVITY' && <ActivityExpereinceV2 />
                        }
                        {/*{*/}
                        {/*    props.experience.type === 'ACTIVITY' && <ActivityExperience slideImageLeft={slideImageLeft} slideImageRight={slideImageRight}/>*/}
                        {/*}*/}

                        {
                            props.experience.type === 'RESOURCE' && <ResourceExperienceV2 />

                        }
                        {/*{*/}
                        {/*    props.experience.type === 'RESOURCE' && <ResourceExperience slideImageLeft={slideImageLeft} slideImageRight={slideImageRight}/>*/}
                        {/*}*/}
                    </div>
                </div>

                {
                    similarExperiences.length &&
                    <div className={`similar-experiences ${experienceState.whiteBg && (widthUpdated > 575.98 || widthUpdated == 0) ? 'white' : ''}`}>
                        <div className={'similar-experiences__top'}>
                            <h3 className={'similar-experiences__top__title'}>{t('Similar experiences')}</h3>
                            <div className={'line'}></div>

                            <p className={'similar-experiences__top__subtitle'}>
                                Discover amazing experiences for your {new Date().getFullYear()} {props.experience.category.translation.name} holiday
                            </p>
                        </div>

                        {
                            <div className={'similar-experiences-list'}>
                                <MasonryGrid experiences={similarExperiences} />
                            </div>
                        }
                    </div>
                }

                {
                    !hideBackButton &&
                    <a onClick={goBack} className={`experience-close-btn ${experienceState.whiteBg && (widthUpdated > 575.98 || widthUpdated == 0) ? 'white' : ''}`}>
                        <img src={experienceState.whiteBg && (widthUpdated > 575.98 || widthUpdated == 0) ? closeIconBlack : closeIcon} />
                        <span>{t("services.and.activities.close")}</span>
                    </a>
                }

            </ExperienceContext.Provider>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        hotel: state.hotel,
        experience: state.experience,
    }
}

export default connect(mapStateToProps)(Experience);
